import React from 'react';
import { graphql } from 'gatsby';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import Selectors from 'src/state/root-selectors';

import { StartTrial, FloatingTryForFree } from 'src/components/shared';
import EquipmentUsed from 'src/components/shared/EquipmentUsed';
import FeaturedTrainers from 'src/components/shared/FeaturedTrainers';
import WorkoutHeaderOverlay from 'src/components/details/WorkoutHeaderOverlay';

import SEO from 'src/components/SEO';

const WorkoutDetailsPageTemplate = ({ data }) => {
    const {
        contentfulWorkout: {
            equipment: workoutEquipment,
            instructor: workoutInstructors,
            name: workoutName,
            slug,
        },
    } = data;
    const entitled = useSelector(Selectors.auth.entitled);

    return (
        <>
            <SEO title={`Workout: ${workoutName}`} />
            <WorkoutHeaderOverlay workout={data.contentfulWorkout} />
            {!isEmpty(workoutEquipment) && (
                <div className="page-section">
                    <EquipmentUsed equipment={workoutEquipment} />
                </div>
            )}
            {!isEmpty(workoutInstructors) && (
                <div className="page-section ">
                    <FeaturedTrainers from={`/workouts/${slug}`} instructors={workoutInstructors} />
                </div>
            )}
            <StartTrial />
            {!entitled && <FloatingTryForFree />}
        </>
    );
};

export const query = graphql`
    query WorkoutDetailsPageQuery($contentful_id: String!) {
        contentfulWorkout(contentful_id: { eq: $contentful_id }) {
            ...ContentfulWorkout_Details
        }
    }
`;

export default WorkoutDetailsPageTemplate;
