// extracted by mini-css-extract-plugin
export const wrapper = "details-header-overlay-module--wrapper--3E3Q-";
export const header = "details-header-overlay-module--header--2xrTb";
export const backButton = "details-header-overlay-module--back-button--3I3a-";
export const level = "details-header-overlay-module--level--32cLn";
export const description = "details-header-overlay-module--description--N2E5e";
export const descriptionLink = "details-header-overlay-module--description-link--usFpR";
export const workoutTitle = "details-header-overlay-module--workout-title--Af-yN";
export const favButton = "details-header-overlay-module--favButton--MJgEu";
export const buttonWrapper = "details-header-overlay-module--button-wrapper--3bLz0";
export const playButton = "details-header-overlay-module--play-button--3eZdB";
export const playButtonIcon = "details-header-overlay-module--play-button-icon--2GgZl";
export const detailsGrid = "details-header-overlay-module--details-grid--16_x_";
export const spinner = "details-header-overlay-module--spinner--1K4iG";
export const thumbnailWrapper = "details-header-overlay-module--thumbnail-wrapper--1awcY";
export const thumbnail = "details-header-overlay-module--thumbnail--1ADTS";
export const previewVideo = "details-header-overlay-module--preview-video--3sRp2";